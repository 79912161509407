<template>
  <form @submit.prevent="handleUpdate">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="user.name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="email">Email:</label>
        <input class="form-control" readonly="" name="email" type="email" v-model="user.email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="phone">Phone:</label>
        <input class="form-control" name="phone" type="text" v-model="user.phone">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
      </div>
    </div>
  </form>
  <form @submit.prevent="handleChangePassword">
    <div class="row">
      <div class="form-group col-sm-6 required">
          <label for="password">Password:</label>
          <input class="form-control" type="password" id="password" name="password" v-model="password" minlength="8" pattern="^(?=.*\d)(?=.*[!@#$%^&*()+\-=[\]{};':\\|,.<>\/?])(?=.*[a-zA-Z]).{8,}$" required>
          <small style="color:red">Minimum of 8 characters, containing at least 1 number, 1 special character, and 1 letter.</small>
      </div>
      <div class="form-group col-sm-6 required">
        <label for="repassword">Repeat Password:</label>
        <input class="form-control" required="" name="repassword" type="password" v-model="repassword">
      </div>
      <div class="form-group col-sm-12">
          <input class="btn btn-warning" type="submit" value="Change Password">
      </div>
      <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
        <img :src="require('../assets/loading.gif')"/>
      </div>
    </div>
 </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    data(){
      return {
        password: '',
        repassword: '',
      }
    },
    computed: {
        ...mapState('users', ['user', 'onRequest'])
    },
    methods: {
      handleUpdate(){
        if(this.onRequest) return;
        const {user} = this
        if (user) {
            this.edit_profile({ user })
        }
      },
      handleChangePassword(){
        if(this.onRequest) return;
        const {password, repassword} = this
        if (password && repassword) {
            this.change_password({ password, repassword })
        }
      },
      ...mapActions('users', ['edit_profile', 'change_password']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Profile')
    },
  }
</script>
