<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="data_mileage" :columns="columns" :title="'Part Mileages'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('part_mileages', ['part_mileages', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'vehicle', text: 'Vehicle', order: 'asc',
              raw: {
                type: 'Sub',
                value: 'vehicle_no'
              }
            },
            {name: 'vehicle', text: 'Vehicle Code',
              raw: {
                type: 'Sub',
                value: 'vehicle_code'
              }
            },
            {name: 'part', text: 'Part',
              raw: {
                type: 'Sub',
                value: 'part_name'
              }
            },
            {name: 'part', text: 'Part Code',
              raw: {
                type: 'Sub',
                value: 'part_code'
              }
            },
            {name: 'initial_km', text: 'Initial Odo KM', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'vehicle', text: 'Current Odo KM', class: 'text-right',
              raw: {
                type: 'Sub_format_number',
                value: 'odometer'
              }
            },
            {name: 'usage_km', text: 'Usage KM', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'reminder_km', text: 'Reminder Usage KM', class: 'text-right',
              raw: {
                type: 'format_number'
              }
            },
            {name: 'status_reminder', text: 'Status Reminder', class: 'text-center',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'Off', class: 'text-success'},
                  {value: '2', text: 'On!!!', class: 'text-danger'},
                ]
              }
            },
            {name: 'status', text: 'Status Part',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'Active'},
                  {value: '2', text: 'Inactive'},
                ]
              }
            },
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_part_mileage', this.user) ? {event: 'edit_part_mileage', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_part_mileage', this.user) ? {event: 'part_mileages/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
      }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
        data_mileage: []
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('part_mileages', ['get_part_mileage_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.get_part_mileage_by_account({account_id: this.account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Part Mileages', getCapability('add_part_mileage', this.user) ? '/part_mileages/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
      part_mileages(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            this.data_mileage = []
            for(let i = 0;i<nextState.length;i++){
              this.data_mileage[i] = JSON.parse(JSON.stringify(nextState[i]))
              let vehicle_odo = 0
              if(this.data_mileage[i].vehicle){
                vehicle_odo = this.data_mileage[i].vehicle.odometer
              }
              this.data_mileage[i].usage_km = vehicle_odo - nextState[i].initial_km
              if(this.data_mileage[i].status == 1 && this.data_mileage[i].usage_km >= this.data_mileage[i].reminder_km){
                this.data_mileage[i].status_reminder = 2
              } else {
                this.data_mileage[i].status_reminder = 1
              }
            }
          }
          return
        }
      },
    }
  }
</script>
