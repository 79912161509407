<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" required v-model.number="payment_link.account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12">
        <label for="sales_id">Sales:</label>
        <Select2 name="sales_id" v-model.number="payment_link.sales_id" :options="salesOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="payment_name">Payment Name:</label>
        <input class="form-control" name="payment_name" required type="text" v-model="payment_link.payment_name" maxlength="255">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="customer_first_name">Customer First Name:</label>
        <input class="form-control" name="customer_first_name" required type="text" v-model="payment_link.customer_first_name" maxlength="50">
      </div>
      <div class="form-group col-sm-6">
        <label for="customer_last_name">Customer Last Name:</label>
        <input class="form-control" name="customer_last_name" type="text" v-model="payment_link.customer_last_name" maxlength="50">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="customer_email">Customer Email:</label>
        <input class="form-control" name="customer_email" required type="email" v-model="payment_link.customer_email" maxlength="50">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="customer_phone">Customer Phone:</label>
        <input class="form-control" name="customer_phone" required type="text" v-model="payment_link.customer_phone" maxlength="50">
      </div>
      <div class="form-group col-sm-12">
        <label for="notes">Note:</label>
        <textarea class="form-control" name="notes" v-model="payment_link.notes" maxlength="255"></textarea>
      </div>
      <div class="form-group col-sm-4 required">
        <label for="usage_limit">Payment Link Usage Limit:</label>
        <input class="form-control" name="usage_limit" required type="number" v-model.number="payment_link.usage_limit">
      </div>
      <div class="form-group col-sm-4 required">
        <label for="expiry_duration">Expiration Duration:</label>
        <input class="form-control" name="expiry_duration" required type="number" v-model.number="payment_link.expiry_duration">
      </div>
      <div class="form-group col-sm-4 required">
        <label for="expiry_unit">Expiration Time Unit:</label>        
        <select class="form-control" required name="expiry_unit" v-model.number="payment_link.expiry_unit">
          <option value="days" style="color:black;">Day</option>
          <option value="hours" style="color:black;">Hour</option>
          <option value="minutes" style="color:black;">Minute</option>
        </select>
      </div>
      <div class="col-sm-12" style="border-top: 1px solid black;padding-top: 20px;margin-top: 20px;margin-bottom: 20px;">
        <div class="row">
          <div class="form-group col-sm-12">
            <h4>Add Detail Payment</h4>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="name">Name:</label>
            <input class="form-control" name="name" type="text" v-model="name">
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="price">Price:</label>
            <input class="form-control" name="price" type="text" v-model="cost_amount_txt">
          </div>
          <div class="form-group col-sm-12 col-md-12">
            <label for="quantity">Qty:</label>
            <input class="form-control" name="quantity" type="number" v-model.number="quantity">
          </div>
          <div class="form-group col-sm-12">
            <button v-if="this.isEdit == false" class="btn btn-success" @click.prevent="clickAdd($event)">Add</button>
            <button v-else class="btn btn-success" @click.prevent="clickAdd($event)">Update</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in payment_link.details" :key="index">
                <td>{{item.name}}</td>
                <td>{{formatNumber(item.price)}}</td>
                <td>{{item.quantity}}</td>
                <td>                  
                  <button class="btn btn-sm btn-info" @click="clickEdit($event, index)">Edit</button>
                  <button class="btn btn-sm btn-danger ml-1" @click="clickRemove($event, index)">Remove</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/payment_links">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('payment_links', ['onRequest']),
        ...mapState('sales', ['saless']),
        cost_amount_txt:{
          get() {
            return this.price ? this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.modelValue
          },
          set(value) {
            this.price = Number(value.replaceAll(',',''))
          }
        }
    },
    data(){
      return {
        payment_link: {
          account_id: '',
          payment_name: '',
          sales_id: '',
          customer_first_name: '',
          customer_last_name: '',
          customer_email: null,
          customer_phone: null,
          usage_limit: 5,
          expiry_duration: 1,
          expiry_unit: 'days',
          notes: null,
          details: []
        },
        accountOptions: [],
        salesOptions: [],
        isEdit: false,
        editIndex: null,
        name: null,
        price: null,
        quantity: null,
      }
    },
    components:{
      Select2
    },
    methods: {
      formatNumber(value){
        return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
      },
      handleSubmit(){
        if(this.onRequest) return;
        if(this.payment_link.details.length < 1){
          return;
        }
        this.add(this.payment_link)
      },
      ...mapActions('payment_links', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('sales', {getSales: 'get_sales_by_account'}),
      clickAdd(){
        if(this.name === null || this.price == null || this.quantity < 0){
          return;
        }
        if(isNaN(this.price)){
          return
        }
        let obj = {
          name: this.name,
          price: this.price,
          quantity: this.quantity,
        }
        if(this.isEdit){
          this.payment_link.details[this.editIndex] = obj
        } else {
          this.payment_link.details.push(obj)
        }
        this.resetData()
      },      
      clickEdit(event, index){
        event.preventDefault()
        const {name, price, quantity} = this.payment_link.details[index]
        this.name = name
        this.price = price
        this.quantity = quantity
        this.isEdit = true
        this.editIndex = index
      },
      clickRemove(event, index){
        event.preventDefault()
        this.payment_link.details.splice(index, 1)
        this.resetData()
      },
      resetData(){
        this.isEdit= false
        this.editIndex= null
        this.name= null
        this.price= null
        this.quantity= null
      },
      onChangeAccount(event){
        this.getSales({account_id: parseInt(event.id)})
        this.sales.sales_id = null
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Payment Link')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      saless(nextState, prevState){
        if(nextState !== prevState){
          this.salesOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.salesOptions.push({id:item.id, text:item.sales_name})
            })
          }
          return
        }
      },
    }
  }
</script>
